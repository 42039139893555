<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/no-parsing-error -->
/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <!-- Form Modal -->
    <b-modal size="md" id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc @ok="submit">
      <!-- Custom Modal Header -->
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 v-if="company.id === null">
          Register Company
        </h5>
        <h5 v-if="company.id !== null">
          Edit Company
        </h5>

        <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
      </template>

      <!-- Custom Modal Footer -->
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" :disabled="saving" @click="ok()">
          <b-spinner v-if="saving" small class="mr-1" label="Small Spinner" />
          <span v-if="!saving">Save</span>
          <span v-if="saving">Saving...</span>
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
      </template>

      <!-- Data Form in Modal Body -->
      <template #default="{ }">
        <validation-observer ref="dataForm" #default="{ invalid }">
          <b-form ref="form" @submit.stop.prevent="handleCreate">
            <b-row>
              <b-col cols="12" md="9">
                <b-form-group label-for="name" label="Company Name">
                  <validation-provider #default="{ errors }" name="Company Name" rules="">
                    <b-form-input id="name" v-model="company.name" name="name" :state="errors.length > 0 ? false : null"
                      size="sm" value="" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="serverErrors && serverErrors.name" class="text-danger">{{ serverErrors.name[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group label-for="abbreviation" label="Abbreviation">
                  <validation-provider #default="{ errors }" name="Abbreviation" rules="">
                    <b-form-input id="abbreviation" v-model="company.abbreviation" name="abbreviation"
                      :state="errors.length > 0 ? false : null" size="sm" value="" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="serverErrors && serverErrors.abbreviation" class="text-danger">{{
                      serverErrors.abbreviation[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group labe-for="email" label="Email">
                  <validation-provider #default="{ errors }" name="Email" rules="">
                    <b-form-input id="email" v-model="company.email" name="email"
                      :state="errors.length > 0 ? false : null" size="sm" value="" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="serverErrors && serverErrors.email" class="text-danger">{{ serverErrors.email[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group labe-for="telephone" label="Telephone">
                  <validation-provider #default="{ errors }" name="Telephone" rules="">
                    <b-form-input id="telephone" v-model="company.telephone" name="telephone"
                      :state="errors.length > 0 ? false : null" size="sm" value="" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="serverErrors && serverErrors.telephone" class="text-danger">{{ serverErrors.telephone[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group labe-for="postbox" label="Post Box">
                  <validation-provider #default="{ errors }" name="Post Box" rules="">
                    <b-form-textarea id="postbox" v-model="company.postbox" name="postbox"
                      :state="errors.length > 0 ? false : null" size="sm" rows="4" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="serverErrors && serverErrors.postbox" class="text-danger">{{ serverErrors.postbox[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group labe-for="address" label="Address">
                  <validation-provider #default="{ errors }" name="Address" rules="">
                    <b-form-textarea id="address" v-model="company.address" name="address"
                      :state="errors.length > 0 ? false : null" size="sm" rows="3" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="serverErrors && serverErrors.address" class="text-danger">{{ serverErrors.address[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-card>
              <!-- {{ company }} -->
            </b-card>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
    <!-- ./Form Modal -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50 select-size-sm" />
            <label>entries</label>
          </b-col>
          <!-- ./Per Page -->

          <!-- Search & Button -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." size="sm" />

              <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                <span class="text-nowrap">Register Company</span>
              </b-button>
            </div>
          </b-col>
          <!-- ./Search & Button-->
        </b-row>
        <!-- ./Table Top -->
      </div>

      <!-- Table -->
      <b-table ref="records" striped hover small class="position-relative" :items="fetch" responsive :fields="columns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found" :sort-desc.sync="isSortDirDesc">
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="invokeUpdateForm(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="remove(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
  BForm, BFormGroup,
  BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import companiesStoreModule from '@/views/cromis/companies/companiesStoreModule'
import useCompaniesList from '@/views/cromis/companies/useCompaniesList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BCardBody,
    BTable,
    BPagination,
    BForm,
    BFormGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelectOption,
    BFormInput,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {},
  props: {},

  setup(props, context) {
    const serverErrors = ref(null)
    const saving = ref(false)
    const dataForm = ref(null)
    const myModal = ref(null)

    const company = ref({
      id: null,
      name: null,
      abbreviation: null,
      email: null,
      telephone: null,
      postbox: null,
      address: null,
    })

    const CROMIS_STORE_MODULE_NAME = 'cromis-company'

    // Register module
    if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, companiesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
    })

    const {
      fetch,
      columns,
      perPage,
      currentPage,
      totalRecords,
      from,
      to,
      meta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      records,
      refetch,
    } = useCompaniesList()

    const isFormValid = async () => {
      let isValid = false
      await dataForm.value.validate().then(success => {
        isValid = success
      })

      return isValid
    }

    const submit = bvModalEvt => {
      bvModalEvt.preventDefault()

      serverErrors.value = null
      // Handle form submit
      // eslint-disable-next-line no-use-before-define
      if (company.value.id === null || company.value.id === 0) { handleCreate() } else { handleUpdate(company.value) }
    }

    const invokeUpdateForm = item => {
      serverErrors.value = null
      saving.value = false

      company.value = {
        id: item.id,
        name: item.name,
        abbreviation: item.abbreviation,
        email: item.email,
        telephone: item.telephone,
        postbox: item.postbox,
        address: item.address,
      }

      myModal.value.show()
    }

    const invokeCreateForm = () => {
      serverErrors.value = null
      saving.value = false

      company.value = {
        id: null,
        name: null,
        abbreviation: null,
        email: null,
        telephone: null,
        postbox: null,
        address: null,
      }

      myModal.value.show()
    }

    const handleCreate = async () => {
      saving.value = true
      const valid = await isFormValid()

      if (!valid) {
        saving.value = false
        return
      }

      await store.dispatch('cromis-company/create', company.value)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          refetch()
          saving.value = false

          myModal.value.hide()

          context.root.$swal({
            icon: 'success',
            text: 'Company registered successfully!',
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          saving.value = false
          if (error.response.status === 422) {
            serverErrors.value = error.response.data.errors
          } else {
            context.root.$swal({
              icon: 'error',
              title: 'Server Error',
              text: 'Something went wrong. See tech support',
              showConfirmButton: true,
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            })
          }
        })
    }

    const handleUpdate = async item => {
      saving.value = true
      const valid = await isFormValid()

      if (!valid) {
        saving.value = false
        return
      }

      await store.dispatch('cromis-company/update', { id: item.id, data: item })
        .then(response => {
          refetch()
          saving.value = false

          myModal.value.hide()

          context.root.$swal({
            icon: 'success',
            text: `Changes to company ${response.data.name} has been saved successfully!`,
            showConfirmButton: true,
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          saving.value = false
          if (error.response.status === 422) {
            serverErrors.value = error.response.data.errors
          } else {
            context.root.$swal({
              icon: 'error',
              title: 'Server Error',
              text: 'Something went wrong. See tech support',
              showConfirmButton: true,
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            })
          }
        })
    }

    const remove = async id => {
      await store.dispatch('cromis-company/remove', id)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          refetch()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          context.root.$swal({
            icon: 'error',
            title: 'Server Error',
            text: 'Something went wrong. See tech support',
            showConfirmButton: true,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            buttonsStyling: true,
          })
        })
    }


    return {
      fetch,
      columns,
      perPage,
      currentPage,
      totalRecords,
      from,
      to,
      meta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      records,
      dataForm,
      myModal,
      company,
      refetch,
      invokeCreateForm,
      invokeUpdateForm,
      isFormValid,
      submit,
      serverErrors,
      saving,
      handleCreate,
      handleUpdate,
      remove,
    }
  },
}
</script>

<style lang="scss" scoped></style>
